.App {
  text-align: center;
}

a, a:active, a:hover {
  text-decoration: none;
  color: white;
  outline: 0;
}

/* Header section */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  z-index: 4;
  background: rgb(43, 43, 43);
}

.header-logo {
  z-index: 5;
  padding: 0px 10px;
  position: fixed;
  display: flex;
  justify-content: flex-start;
}

.header-name {
  z-index: 4;
  color: #edf2f4;
  border: 1px solid #edf2f4;
  padding: 0.5em;
  font-size: 1.2rem;
  animation: show 0.5s;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.header-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  align-items: center;
  padding: 1.65rem;
  text-align: right;
  color: lightgrey;
  z-index: 4;
  transition: all .2s linear;
}

.nav-item {
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  margin-right: 2rem;
}

.nav-item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: .2rem;
  opacity: 0;
  bottom: -1rem;
  left: 0;
  transition: all .1s ease-in-out;
  background-color: lightgrey;
}

.nav-item:hover:after {
  opacity: 1;
}
/* End Header section */

/* Body Section */ 
.body {
  position: relative;
  background-color: #343434;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 56rem;
}

.body .body-container {
  position: relative;
  display: flex;
  max-width: 120rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.body-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 20rem;
}

.body-img {
  width: 120%;
  transform: scale(.95);
  position: relative;  /* position the image relative to its normal position */
  z-index: 3;  /* set the z-index of the image to 1 to ensure it is positioned above the circular hole */
  width: 200px;  /* set the width of the image to 200 pixels */
  height: 200px;  /* set the height of the image to 200 pixels */
}

.hole {
  position: absolute;  /* position the hole absolutely, relative to its parent container */
  z-index: 2;  /* set the z-index of the hole to 0 to ensure it is positioned below the image */
  top: 42%;  /* position the hole at the center of the image vertically */
  left: 50%;  /* position the hole at the center of the image horizontally */
  transform: translate(-50%, -50%);  /* shift the hole 50% left and 50% up to properly center it */
  width: 150px;  /* set the width of the hole to 150 pixels */
  height: 150px;  /* set the height of the hole to 150 pixels */
  border-radius: 50%;  /* make the hole circular */
  border-style: solid;
  box-shadow: 0px -5px 20px 10px rgb(164, 164, 164);
  border-color: #ffffff6d;  /* set the background color of the hole to white (#ffffff) */
}

.body-content {
  padding-top: 2rem;
  text-align: center;
  z-index: 2;
}

.body-headline {
  font-size: 3rem;
  padding-bottom: 1rem;
  color: white;
  z-index: 2;
}

.body-text {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: lightgray;
  z-index: 2;
}

.body-icons {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 2.8rem;
  z-index: 2;
}

.body .icon-link {
  padding: 0.8rem 0.8rem;
  color: white;
  z-index: 2;
}

.icon-link:hover {
  color: lightgray;
  transform: scale(1.05);
  z-index: 2;
}
/* End Body Section */ 

/* About Section  */
.about {
  height: 72vh;
  width: 100%;
  height: auto;
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.about-heading {  
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: .3rem;
  color: rgb(156, 156, 156);
}

.about-info {
  display: flex;
  justify-content: space-evenly;
}

.about .about-info .about-desc {
  font-size: 1.7rem;
  line-height: 2rem;
  max-width: 50%;
  margin-top: -0.2rem;
  /* overflow: scroll; */
  display: flex;
  display: block;
  align-items: center;
  color: rgb(151, 151, 151);
}

.about .about-img {
    display: block;
}

.about .about-img .about-img-wrapper {
  padding: 15px;
  margin-top: 30px;
  height: auto;
  max-width: 400px;
  position: relative;
  display: inline-block;
  border-radius: 60%;
  border: 1vh solid #cbd5e1;
}
.about .about-img .about-img-wrapper img {
  width: 60%;
}
/* End About Section  */

/* Projects section */
.projects {
  background-color: #ffffff;
  height: 65vh;
  margin-top: -2rem;
}
.heading {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1rem;
  text-align: center;
  letter-spacing: .3rem;
}
.projects-container {
  padding: 3rem;
}
.projects-container .project {
  position: relative;
  height: 38vh;
  border: 0.3rem solid black;
  border-radius: 20px;
  margin: 0 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.projects-container .project .content {
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.2rem;
  height: 85%;
  border-radius: 15px;
  transition: all 0.5s;
  cursor: auto;
  overflow: hidden;
}
.content:hover {
  transform: translate(0, -10px);
}
.content:before {
  content: "";
  display: block;
  padding-top: 10%;
}
.projects-container .project .content img{
  display: block;
  margin: 0 auto;
  width: 31%;
}

.name {
  color: #2b2d42;
  margin: 10px 0;
  font-size: 1.6rem;
  margin-bottom: -0.5rem;
}
.description {
  color: #2b2d42;
  font-size: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 1.5rem;
}
.description-min {
  color: #2b2d42;
  font-size: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3; 
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.project-button {
  width: 8rem;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 5px 25px;
  border: 1px solid rgba(30, 39, 46, 0.7);
  border-radius: 8%;
  color: rgba(30, 39, 46, 0.7);
  margin: 0 auto;
}
.project-button:hover,
.project-button:active,
.project-button:focus{
  background-color: #ef233c;
  border-color: #ef233c;
  color: white;
}
@media (min-width: 500px) and (max-width: 650px) { 
  .projects-container .project {
    position: relative;
    height: 38vh;
    border: 0.3rem solid black;
    border-radius: 20px;
    margin: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .projects-container .project .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1.2rem;
    height: 88%;
    border-radius: 15px;
    transition: all 0.5s;
    cursor: auto;
    overflow: hidden;
  }
  .projects-container .project .content img{
    display: block;
    margin: 0 auto;
    width: 31%;
  }
  .name {
    color: #2b2d42;
    margin: 10px 0;
    font-size: 1.4rem;
    margin-bottom: -0.5rem;
  }
  .description {
    color: #2b2d42;
    font-size: 1rem;
    padding: 0rem 1rem;
    margin-bottom: 1.2rem;
  }
  .description-min {
    color: #2b2d42;
    font-size: 1rem;
    padding: 0rem 1rem;
    margin-bottom: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3; 
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .project-button {
    width: 8rem;
    font-size: 1.05rem;
    font-weight: 800;
    padding: 3px 25px;
    border: 1px solid rgba(30, 39, 46, 0.7);
    border-radius: 8%;
    color: rgba(30, 39, 46, 0.7);
  }
}
@media (min-width: 1000px) { 
  .projects-container {
    padding: 3rem;
  }
  .projects-container .project {
    position: relative;
    height: 38vh;
    border: 0.3rem solid black;
    border-radius: 20px;
    margin: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .projects-container .project .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1.2rem;
    height: 85%;
    border-radius: 15px;
    transition: all 0.5s;
    cursor: auto;
    overflow: hidden;
  }
  .content:hover {
    transform: translate(0, -10px);
  }
  .content:before {
    content: "";
    display: block;
    padding-top: 10%;
  }
  .projects-container .project .content img{
    display: block;
    margin: 0 auto;
    width: 31%;
  }
  .name {
    color: #2b2d42;
    margin: 10px 0;
    font-size: 1.6rem;
    margin-bottom: -0.5rem;
  }
  .description {
    color: #2b2d42;
    font-size: 1rem;
    padding: 0rem 1rem;
    margin-bottom: 1.5rem;
  }
  .description-min {
    color: #2b2d42;
    font-size: 0.8rem;
    padding: 0rem 1rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3; 
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .project-button {
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 800;
    padding: 4px 25px;
    border: 1px solid rgba(30, 39, 46, 0.7);
    border-radius: 8%;
    color: rgba(30, 39, 46, 0.7);
  }
}
@media (min-width: 2000px) { 
  .projects-container {
    padding: 3rem;
  }
  .projects-container .project {
    position: relative;
    height: 38vh;
    border: 0.3rem solid black;
    border-radius: 20px;
    margin: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .projects-container .project .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1.2rem;
    height: 85%;
    border-radius: 15px;
    transition: all 0.5s;
    cursor: auto;
    overflow: hidden;
  }
  .content:hover {
    transform: translate(0, -10px);
  }
  .content:before {
    content: "";
    display: block;
    padding-top: 10%;
  }
  .projects-container .project .content img{
    display: block;
    margin: 0 auto;
    width: 35%;
  }
  .name {
    color: #2b2d42;
    margin: 10px 0;
    font-size: 1.6rem;
    margin-bottom: -0.2rem;
  }
  .description {
    color: #2b2d42;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    margin-bottom: 1.7rem;
  }
  .description-min {
    color: #2b2d42;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    margin-bottom: 1.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3; 
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .project-button {
    width: 8rem;
    font-size: 1.4rem;
    font-weight: 800;
    padding: 7px 25px;
    border: 1px solid rgba(30, 39, 46, 0.7);
    border-radius: 8%;
    color: rgba(30, 39, 46, 0.7);
  }
}
/* End About Section  */

/* Skills section */
.skills {
  background-color: rgb(110, 110, 110);
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-slider .slick-prev {
  color: black; /* Changes the color of the arrow */
  background: black; /* If you want to change the background */
}
.custom-slider .slick-next {
  color: black; /* Changes the color of the arrow */
  background: black; /* If you want to change the background */
}

.custom-slider .slick-next:before .slick-prev:before{
  color: black; /* Changes the color of the arrow icon specifically */
}

.title {
  font-size: 4rem;
  color: black;
  margin: 3rem 0;
  text-align: center;
  margin-top: 5%;
  letter-spacing: 0.3rem;
}

.skill-cards {
  background-color: gray;
  padding: 1.2rem 1rem 1rem 1rem;
  margin: 1.2rem;
    width: 10rem;
  border-radius: 8px;
  color: #ffffff;
}

.skill-icon {
  color: white;
  font-size: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.skill-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  margin: 2em 0;
}

.skill {
  font-size: 1.3rem;
  font-weight: bolder;
  text-align: center;
  text-shadow: 2px 3px 4px #000000;
  text-transform: uppercase;
}

.skill-cards:hover {
  box-shadow: 0px 2px 10px #000000;
}

@media (max-width: 500px) { 
  .title {
    font-size: 4rem;
    color: black;
    margin: 3rem 0;
    text-align: center;
    margin-top: 8%;
    letter-spacing: 0.3rem;
  }

  .skill-cards {
    background-color: gray;
    padding: 1.2rem 1rem 1rem 1rem;
    margin: 0.2rem;
    width: 7em;
    border-radius: 8px;
    color: #ffffff;
  }

  .skill-icon {
    color: white;
    font-size: 40px;
  }

  .skill-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    margin: 4em 0;
  }

  .skill {
    padding: 0;
    font-size: 0rem;
  } 
} 
/* End Skills section */

/* Footer section  */
.footer {
    min-height: 20vh;
    width: 100%;
    background-color: black;
    color: white;
    flex-direction: column;
    text-align: center;
    padding: 5rem;
}
.footer .footer-contact-info {
    padding: 20px;
}
.footer .footer-contact-info .footer-heading {
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.footer .footer-contact-info .footer-contact-access {
    font-size: 1.4rem;
    padding-top: 10px;
    letter-spacing: .2rem;
  margin-bottom: -0.1rem;
}
.footer .social-icons a {
    color: white;
    font-size: 3rem;
    margin: 0.7rem;
    display: inline-block;
}
/* End Footer section  */

.cube {
  z-index: -1;
  position:fixed; /* position the component absolutely within the parent element */
  left: 50%; /* align the left edge of the component with the horizontal center of the parent element */
  top: 50%; /* align the top edge of the component with the vertical center of the parent element */
  transform: translate(-50%, -50%); /* move the component back by half its width and height to center it within the parent element */
  overflow: hidden; /* hide the content of the component when it overflows its bounds */
}

.raindrop {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
}
.rain-scene {
  position: absolute;
  width: 100%;
  height: 100%;

}

.random-letter {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: rgb(87, 87, 87);
  /* background: rgba(0, 255, 55, 0.285); */
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}
.random-letter-grid {
  position: absolute;
  z-index: 1;
}

